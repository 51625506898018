import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ACTIVE_PORTFOLIO_ACTIVATION, ACTIVE_PORTFOLIO_DASHBOARD, ACTIVE_PORTFOLIO_PROPERTY } from "routing/routes";
import Layout from "shared/layout/layout";
const Activation = lazy(() => import("./activation/activation"));
const Dashboard = lazy(() => import("./dashboard/dashboard"));
const Property = lazy(() => import("./property/property"));

const ActivePortfolioRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path={ACTIVE_PORTFOLIO_ACTIVATION} element={<Activation />} />
        <Route path={ACTIVE_PORTFOLIO_DASHBOARD} element={<Dashboard />} />
        <Route path={ACTIVE_PORTFOLIO_PROPERTY} element={<Property />} />
      </Routes>
    </Layout>
  );
};

export default ActivePortfolioRoutes;
