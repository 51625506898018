import styled from "styled-components";
import { mediaUp, rem } from "@mc/components/core/styles";
import { HeaderStyled, HEADER_HEIGHT } from "./header/header.styles";
import { LayoutColour } from "./layout.constants";
import { Col } from "@mc/components/grid/grid.styles";

type LayoutMainProps = {
  backgroundColor?: LayoutColour;
  isNormalFlowingPage?: boolean;
};
export const LayoutMainStyled = styled.main<LayoutMainProps>`
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background-color: ${backgroundColor};
    ${HeaderStyled} {
      background-color: ${backgroundColor};
    }
  `};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  ${({ isNormalFlowingPage }) =>
    !isNormalFlowingPage &&
    `
    min-height: calc(100vh - ${rem(HEADER_HEIGHT)});
    padding-top: ${rem(HEADER_HEIGHT)};
  `};
`;

export const LayoutContainerFluidStyled = styled.div`
  padding: 0 ${rem(24)};
`;

type LayoutContentStyledProps = {
  paddingTop?: number;
};
export const LayoutContentStyled = styled.div<LayoutContentStyledProps>`
  padding: ${({ paddingTop = 64 }) => rem(paddingTop)} 0 ${rem(64)} 0;
`;

export const SupportImageContainerStyled = styled(Col as any)`
  text-align: center;
  margin-bottom: ${rem(40)};
`;

export const SupportImageStyled = styled.img`
  max-width: ${rem(140)};
  width: 100%;

  ${mediaUp.md`
    max-width: ${rem(300)};
  `}
`;
