import { generatePath, useNavigate, useMatch } from "react-router-dom";

import { ACCOUNT_PAYMENTS, ACCOUNT_PROFILE, GAME_PLAN, HOME, LOGIN } from "routing/routes";
import { ButtonIcon } from "@mc/components/buttons";
import { useHeaderScroll } from "./header.hooks";
import { HeaderStyled, HeaderLogoLinkStyled, HeaderMenuContainerStyled, TopBar } from "./header.styles";
import { LayoutColour } from "../layout.constants";
import Icon from "@mc/components/icons/Icon";
import DropdownMenu, { Options } from "@mc/components/dropdown/dropdown-menu";
import { useUser } from "user/user.hooks";
import * as Styled from "../navigation/navigation.styles";
import { clearUserStorage } from "user/user.storage";
import { useApolloClient } from "@apollo/client";
import { AnchorStyled } from "@mc/components/core/typography.styles";
import { white } from "@mc/components/core/colours.styles";

type Props = {
  isNormalFlowingPage?: boolean;
  backgroundColor?: LayoutColour;
  goBackTo?: string;
  goBack?: boolean;
};

const Header = ({ isNormalFlowingPage, backgroundColor, goBackTo, goBack }: Props) => {
  const navigate = useNavigate();
  const { hasScrolled } = useHeaderScroll();
  const { user } = useUser();
  const client = useApolloClient();

  const isProfile = !!useMatch(ACCOUNT_PROFILE);

  const menuOptions = [
    {
      label: "0",
      options: [
        {
          value: "PROFILE",
          label: "Profile",
          isActive: isProfile,
        },
        {
          value: "GAME_PLAN",
          label: "Game Plan",
        },
        {
          value: "SIGN_OUT",
          label: "Sign out",
        },
      ],
    },
  ];

  const onSelect: (option: Options) => void = async (opt) => {
    switch (opt.value) {
      case "PROFILE":
        navigate(generatePath(ACCOUNT_PROFILE));
        break;

      case "GAME_PLAN":
        navigate(GAME_PLAN, { replace: true });
        break;

      case "SIGN_OUT":
        clearUserStorage();
        client.clearStore();
        navigate(LOGIN, { replace: true });
        break;
    }
  };

  const foundFailedPaymentAttempts = user?.jobs?.find((job) => job.failedPaymentAttempts)?.failedPaymentAttempts;

  return (
    <>
      {foundFailedPaymentAttempts &&
        (foundFailedPaymentAttempts >= 3 ? (
          <TopBar>
            Please{" "}
            <AnchorStyled to={ACCOUNT_PAYMENTS} style={{ color: white }}>
              update your payment details
            </AnchorStyled>{" "}
            to avoid losing access.
          </TopBar>
        ) : foundFailedPaymentAttempts > 0 ? (
          <TopBar>
            Oops, it looks like your latest payment didn't go through! Please check your financial institution or{" "}
            <AnchorStyled to={ACCOUNT_PAYMENTS} style={{ color: white }}>
              update your payment details
            </AnchorStyled>
            .
          </TopBar>
        ) : null)}
      <HeaderStyled
        hasScrolled={hasScrolled}
        isNormalFlowingPage={isNormalFlowingPage}
        backgroundColor={backgroundColor}
      >
        {goBackTo || goBack ? (
          <HeaderMenuContainerStyled>
            <ButtonIcon
              type="button"
              noBorder
              iconId="close"
              onClick={() => (goBackTo ? navigate(goBackTo, { replace: true }) : goBack ? navigate(-1) : false)}
            />
          </HeaderMenuContainerStyled>
        ) : (
          <>
            <HeaderLogoLinkStyled to={HOME}>
              <Icon id="milkChocolateLogo" aria-label="Milk Chocolate Property Logo" width={150} />
            </HeaderLogoLinkStyled>
            {user && (
              <DropdownMenu
                $withBorder={false}
                options={menuOptions}
                onSelect={onSelect}
                noBackgroundColor
                icon={<Styled.MenuAvatar firstName={user?.firstName} lastName={user?.lastName} />}
              />
            )}
          </>
        )}
      </HeaderStyled>
    </>
  );
};

export default Header;
