import styled from "styled-components";
import { rem } from "@mc/components/core/styles";
import { pureBlack, tan, white } from "@mc/components/core/colours.styles";

export const FOOTER_HEIGHT = 72;

export const FooterStyled = styled.footer`
  background-color: ${pureBlack};
  height: ${rem(FOOTER_HEIGHT)};
  display: flex;
  align-items: center;
  justify-content: center;

  > div a {
    color: ${white};
  }

  > div p {
    color: ${tan};
  }
`;
