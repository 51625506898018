import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Modal from "react-modal";
import { client } from "./apollo";
import { ApolloProvider } from "@apollo/client";
import PrivateRoute from "./routing/private-route";
import {
  LOGIN,
  GUIDE,
  GET_IN_TOUCH_PAGE,
  CORE_LOGIC_DISCLAIMER,
  JOIN_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  ACCOUNT_ROUTE,
  GAME_PLAN_ROUTE,
  ONBOARDING_ROUTE,
  SUBURB,
  PURCHASE_PROPERTY_ROUTE,
  SEARCH_TRACKER,
  PURCHASE,
  CHART_DISCLAIMER,
  GAME_PLAN_FAQS,
  ACTIVE_PORTFOLIO_ROUTE,
} from "./routing/routes";
import { ModalStyles } from "@mc/components/modal/modal.styles";
import { GlobalStyles } from "@mc/components/core/global.styles";
import { NotificationContextProvider } from "@mc/components/notification/notification.context";
import Notification from "@mc/components/notification/notification";
import Tooltip from "@mc/components/tooltips/tooltip";
import ErrorBoundary from "error-boundary";
import StartUp from "startup";
import { Wrapper as GoogleMapsWrapper } from "@googlemaps/react-wrapper";
import { ScrollToTop } from "@mc/common/utils";
import ActivePortfolioRoutes from "active-portfolio/active-portfolio.routes";

const Login = lazy(() => import("./user/login/login"));

const Home = lazy(() => import("./home/home"));
const Guide = lazy(() => import("./guide/guide"));
const GamePlanFaqs = lazy(() => import("./game-plan-faqs/game-plan-faqs"));
const GetInTouchPage = lazy(() => import("./shared/footer/get-in-touch-page"));
const OnboardingRoutes = lazy(() => import("./onboarding/onboarding.routes"));
const SearchTrackerHome = lazy(() => import("./search-tracker/search-tracker-home"));
const SearchTrackerRoutes = lazy(() => import("./search-tracker/search-tracker.routes"));
const PurchaseTrackerRoutes = lazy(() => import("./purchase-tracker/purchase-tracker.routes"));
const ForgotPasswordRoutes = lazy(() => import("./user/forgot-password/forgot-password.routes"));
const JoinRoutes = lazy(() => import("./user/join/join.routes"));
const AccountRoutes = lazy(() => import("./account/account.routes"));
const GamePlanRoutes = lazy(() => import("./game-plan/game-plan-routes"));
const NotFound = lazy(() => import("./errors/not-found"));
const CoreLogicDisclaimer = lazy(
  () => import("@mc/common/blocks/disclaimer/core-logic-disclaimer/core-logic-disclaimer")
);
const ChartDisclaimer = lazy(() => import("disclaimer/chart-disclaimer/chart-disclaimer"));
const SuburbSummary = lazy(() => import("./suburb-summary/suburb-summary"));

Modal.setAppElement("#root");

function App() {
  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <GoogleMapsWrapper apiKey={process.env.REACT_APP_GOOGLE_PLACES_KEY!} libraries={["drawing", "places"]}>
          <StartUp>
            <ModalStyles />
            <GlobalStyles />
            <Tooltip />
            <NotificationContextProvider>
              <Router>
                <ScrollToTop />
                <Notification />
                <Suspense fallback={""}>
                  <Routes>
                    <Route path={LOGIN} element={<Login />} />
                    <Route path={JOIN_ROUTE} element={<JoinRoutes />} />
                    <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPasswordRoutes />} />
                    <Route path={GUIDE} element={<Guide />} />
                    <Route path={GAME_PLAN_FAQS} element={<GamePlanFaqs />} />
                    <Route path={CORE_LOGIC_DISCLAIMER} element={<CoreLogicDisclaimer />} />
                    <Route path={CHART_DISCLAIMER} element={<ChartDisclaimer />} />
                    <Route path={GET_IN_TOUCH_PAGE} element={<GetInTouchPage />} />
                    <Route path="/" element={<PrivateRoute element={<Home />} />} />
                    <Route path={ACCOUNT_ROUTE} element={<PrivateRoute element={<AccountRoutes />} />} />
                    <Route path={GAME_PLAN_ROUTE} element={<PrivateRoute element={<GamePlanRoutes />} />} />
                    <Route path={ONBOARDING_ROUTE} element={<PrivateRoute element={<OnboardingRoutes />} />} />
                    <Route path={SEARCH_TRACKER} element={<PrivateRoute element={<SearchTrackerHome />} />} />
                    <Route path={PURCHASE} element={<PrivateRoute element={<SearchTrackerRoutes />} />} />
                    <Route
                      path={PURCHASE_PROPERTY_ROUTE}
                      element={<PrivateRoute element={<PurchaseTrackerRoutes />} />}
                    />
                    <Route path={SUBURB} element={<SuburbSummary />} />
                    <Route path={ACTIVE_PORTFOLIO_ROUTE} element={<ActivePortfolioRoutes />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </Router>
            </NotificationContextProvider>
          </StartUp>
        </GoogleMapsWrapper>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
