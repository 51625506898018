import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { rem, standardFocusState } from "@mc/components/core/styles";
import { fontSize16, fontWeightMedium } from "@mc/components/core/typography.styles";
import { black, lightPink } from "@mc/components/core/colours.styles";
import Avatar from "@mc/components/avatar/avatar";
import { AVATAR_SMALL } from "@mc/components/avatar/avatar.styles";
import { HEADER_HEIGHT } from "../header/header.styles";

export const MENU_DRAWER_TRANSITION = 200;

type MenuDrawerProps = {
  isActive: boolean;
};

export const MenuDrawer = styled.nav<MenuDrawerProps>`
  background-color: ${lightPink};
  position: fixed;
  padding: ${rem(HEADER_HEIGHT)} 0;
  bottom: 0;
  right: 0;
  top: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: ${rem(294)};
  z-index: 2;
  transition: all ${MENU_DRAWER_TRANSITION}ms ease;
  transform: translate3d(100%, 0, 0);

  ${({ isActive }) =>
    isActive &&
    `
    transform: translate3d(0, 0, 0);
  `}

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    margin-top: ${rem(32)};
  }

  li {
    padding: 0 ${rem(20)};
  }
`;

const navigationLinkBase = css`
  background: transparent;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  color: ${black};
  display: block;
  font-weight: ${fontWeightMedium};
  font-size: ${fontSize16};
  padding: ${rem(8)};
  text-align: left;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: ${black};
    cursor: pointer;
    text-decoration: none;
  }

  ${standardFocusState}
`;

export const MenuNavButton = styled.button.attrs({
  type: "button",
})`
  ${navigationLinkBase}
`;

export const MenuNavLink = styled(Link)`
  ${navigationLinkBase}
`;

export const MenuUnderlay = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const MenuAvatar = styled(Avatar).attrs({
  size: AVATAR_SMALL,
})``;
